import React, { useState } from "react";
import ServiceBg from "../../../assets/marketing/ServicesBgImg.webp";
import { ServiceCardData } from "../ServicePage/ServiceCardData";
import package1 from "../../../assets/marketing/rectangle.webp";
import package2 from "../../../assets/marketing/rectangle1.webp";
import { PopupButton } from "react-calendly";
import { CONTACT_US_CALENDLY_URL } from "../../../utils/constant/constants";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";

function ServiceSectionNew() {
  const [expandedCard, setExpandedCard] = useState(null);

  // Toggle function to show/hide content
  const toggleReadMore = (id) => {
    setExpandedCard(expandedCard === id ? null : id);
  };

  const [showFullContent1, setShowFullContent1] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);

  return (
    <div className="pt-[80px]">
      <div className="max-w-[1280px] mx-[auto] h-auto relative">
        <img
          src={ServiceBg}
          alt="ServiceBg"
          className="w-full h-auto rounded-none m-[0]"
        ></img>

        <div
          className="absolute bottom-0 mb-[80px] ml-[80px] 
       tablet:mb-[40px] tablet:ml-[40px]
       mobile:mb-[20px] mobile:ml-[20px]
      "
        >
          <h1
            className="font-lejour text-[clamp(35px,_3vw,_70px)] font-normal leading-[70px] text-left m-0 text-clientWhite
        "
          >
            Services
          </h1>
          <div className="w-[65%] tablet:w-[70%] mobile:w-[90%]">
            <p
              className="font-glacial text-[20px] font-bold leading-[32px] text-left m-0 text-clientWhite uppercase
             tablet:text-[15px]
             tablet:leading-[24px]
             mobile:text-[14px]
             mobile:leading-[20px] "
            >
              all services are monthly subscriptions. THE Monthly cost does not
              include THE hard costs of marketing.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[1280px] mx-[auto] bg-[#F3F3F3]">
        <div className="max-w-[1180px] mx-auto w-full px-[20px]">
          <p className="font-canela-trial p-[30px] pt-[40px] w-full max-w-[1024px] mx-auto text-[28px] font-bold leading-[42px] text-center text-clientNero smallView:text-[20px] smallView:leading-[27px] smallView:px-0">
            We execute for you. Don’t worry about new marketing trends.Focus on
            selling while we handle your marketing operations.
          </p>
        </div>
        <div className="bg-[#1E1E1E] max-w-[1280px] mx-auto w-full md:mt-[280px] px-[30px] md:pt-0 pt-[30px]">
          <div className="flex justify-center md:flex-row flex-col mx-auto w-[fit-content]">
            <div className="md:mr-[20px] w-full max-w-[487px]">
              <div>
                <img
                  src={package1}
                  alt={package1}
                  className="w-full max-w-[487px] h-auto rounded-[13px] object-cover m-[0] md:mt-[-260px]"
                />
                <div className="px-4 flex flex-col">
                  <div className="mt-[20px] w-full h-auto md:h-[calc(3*1.5rem)] tablet:h-[calc(4*1.5rem)] mobile:h-[calc(3*1.5rem)]">
                    <p className="font-lejour text-[clamp(16px,_3vw,_19px)] font-normal leading-[34.2px] uppercase m-0 text-clientWhite">
                      The Premier Package -
                      <span className="font-glacial text-[clamp(16px,_3vw,_19px)] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_1)]">
                        $850
                      </span>
                      <span className="font-bold">/</span>
                      month
                    </p>
                  </div>

                  <div className="flex-grow mb-[10px] mt-[10px] lg:mt-[20px] md:mt-[20px] sm:mt-[40px] mobile:mt-[25px] h-auto w-full text-[#CFCFCF]">
                    <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                      Business consultation & growth strategies
                    </li>
                    <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                      Marketing design & execution
                    </li>
                    <div>
                      {showFullContent1 ? (
                        <React.Fragment>
                          <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                            Email Marketing
                          </li>
                          <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                            Social Media Marketing
                          </li>
                          <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                            Direct Mail Marketing
                          </li>
                          <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                            Digital Ads
                          </li>
                          <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                            Listing Marketing
                          </li>
                          <div className="ml-[30px]">
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Listing Flyers & Social Media
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Ads
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Client Letter
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Property Sites, etc.
                            </li>
                          </div>

                          <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                            Post-Close Marketing
                          </li>
                          <div className="ml-[30px]">
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Social Media
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Client Letter
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Request for Review
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              Client follow up
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                              CRM Management
                            </li>
                          </div>

                          <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                            Additional design work & more!
                          </li>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>

                    <button
                      type="button"
                      onClick={() => setShowFullContent1(!showFullContent1)}
                      className="flex items-center font-bold mt-[20px] text-clientWhite text-[12px] leading-[16.8px] cursor-pointer"
                    >
                      {showFullContent1 ? "READ LESS" : " READ MORE"}

                      {showFullContent1 ? (
                        <GoArrowLeft className="text-[#9A9A9A] text-[20px] ml-1 mb-[2px]" />
                      ) : (
                        <GoArrowRight className="text-[#9A9A9A] text-[20px] ml-1 mb-[2px]" />
                      )}
                    </button>

                    <div className="mt-4">
                      <PopupButton
                        className="font-glacial my-[18px] bg-clientWhite rounded-[6px] mb-[50px] border border-primary h-[60px] w-full text-[20px] smallView:text-[15px] font-bold leading-[28px] text-clientNero cursor-pointer"
                        url={CONTACT_US_CALENDLY_URL}
                        rootElement={document.getElementById("root")}
                        text="Reach out to discuss"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:ml-[20px] w-full max-w-[487px]">
              <div>
                <img
                  src={package2}
                  alt={package2}
                  className="w-full max-w-[487px] h-auto rounded-[13px] object-cover m-[0] md:mt-[-260px]"
                />
                <div className="px-4 flex flex-col">
                  <div className="mt-[20px] w-full h-auto md:h-[calc(3*1.5rem)] tablet:h-[calc(4*1.5rem)] mobile:h-[calc(3*1.5rem)]">
                    <p className="font-lejour text-[clamp(16px,_3vw,_19px)] font-normal leading-[34.2px] uppercase m-0 text-clientWhite ">
                      Brokerage Marketing and Strategy -
                      <span className="font-glacial text-[clamp(16px,_3vw,_19px)] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_1)]">
                        $1,650
                      </span>
                      <span className="font-bold">/</span>
                      month +
                      <span className="font-glacial text-[clamp(16px,_3vw,_19px)] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_1)]">
                        $150
                      </span>
                      /consultation fee
                    </p>
                  </div>
                  <div className="flex-grow mb-[10px] mt-[10px] lg:mt-[20px] md:mt-[20px] sm:mt-[40px] mobile:mt-[25px] h-auto w-full text-[#CFCFCF]">
                    <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                      Best for boutique brokerages who are looking to grow
                    </li>
                    <li className="mb-[10px] font-glacial text-[18px] font-normal leading-[25.2px] text-[#CFCFCF]">
                      Comprehensive marketing director services:
                    </li>
                    <div>
                      {showFullContent ? (
                        <React.Fragment>
                          <div className="ml-[30px]">
                            <li className="mb-[10px] font-glacial text-[18px] font-normal  leading-[25.2px] text-[#CFCFCF]">
                              Evaluation and assessment of current marketing
                              materials.
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal  leading-[25.2px] text-[#CFCFCF]">
                              Develop new marketing content, including emails,
                              social media, direct mail, and internet ads
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal  leading-[25.2px] text-[#CFCFCF]">
                              Establish branding guidelines and create marketing
                              templates for brokerage use.
                            </li>
                            <li className="mb-[10px] font-glacial text-[18px] font-normal  leading-[25.2px] text-[#CFCFCF]">
                              Agent growth and marketing consultations.
                            </li>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>

                    <button
                      type="button"
                      onClick={() => setShowFullContent(!showFullContent)}
                      className="flex items-center text-clientWhite text-[12px] font-bold mt-[20px] leading-[16.8px] cursor-pointer"
                    >
                      {showFullContent ? "READ LESS" : " READ MORE"}

                      {showFullContent ? (
                        <GoArrowLeft className="text-[#9A9A9A] text-[20px] ml-1 mb-[2px]" />
                      ) : (
                        <GoArrowRight className="text-[#9A9A9A] text-[20px] ml-1 mb-[2px]" />
                      )}
                    </button>

                    <div className="mt-4">
                      <PopupButton
                        className="font-glacial my-[18px] bg-clientWhite rounded-[6px] mb-[50px] border border-primary h-[60px] w-full text-[20px] smallView:text-[15px] font-bold leading-[28px] text-clientNero cursor-pointer"
                        url={CONTACT_US_CALENDLY_URL}
                        rootElement={document.getElementById("root")}
                        text="Reach out to discuss"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full max-w-[1280px] mx-[auto] bg-[#F1F1F1]">
        <div className="max-w-[1180px] mx-auto w-full p-[20px]">
          <h3 className="text-[30px] my-[20px] mb-[35px] font-bold leading-[42px] text-center text-clientBlack ">
            Additional Services
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 w-full mx-auto">
            {ServiceCardData.map((service, index) => (
              <div key={index} className="overflow-hidden">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full max-w-[321px] h-auto mx-auto rounded-[13px] object-cover m-0"
                />
                <div className="px-4">
                  <div className="mt-[20px] w-full h-auto md:h-[calc(3*1.5rem)] sm:h-[calc(4*1.5rem)] mobile:h-[unset] tabSection:h-[calc(4*1rem)]">
                    <p className="font-lejour text-[19px] font-normal leading-[34.2px] uppercase m-0 text-clientBlack text-center sm:text-start">
                      {service.headingStart}{" "}
                      <span className="font-glacial text-[19px] font-bold leading-[24.2px] m-0 text-[rgba(204,_29,_185,_1)]">
                        {service.headingMiddle}
                      </span>
                      <span className="font-bold">/</span>
                      {service.headingEnd}
                    </p>
                  </div>
                  <div className="mt-[20px] lg:mt-[20px] tabSection:mt-[20px] sm:mt-[40px] mobile:mt-[25px] h-auto w-full mx-auto max-w-[321px] sm:max-w-[unset]">
                    {expandedCard === service.id
                      ? service.listingPint.map((listItem, index) => {
                          return (
                            <li
                              className="font-glacial text-[16px] font-normal leading-[23.84px] m-0 text-clientDarkCharcoal "
                              key={index}
                            >
                              {listItem}
                            </li>
                          );
                        })
                      : ""}
                  </div>
                  <div className="flex justify-center sm:justify-start">
                    <button
                      type="button"
                      className="mt-4 px-4 py-2 text-[15px] font-normal leading-[15px] border-[1.5px] border-primary rounded-[4px]"
                      onClick={() => toggleReadMore(service.id)}
                    >
                      {expandedCard === service.id ? "Read Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <PopupButton
              className="h-[60px] w-full max-w-[298px] bg-primary text-[18px] font-bold leading-[25.2px] text-clientWhite rounded-[6px]"
              url={CONTACT_US_CALENDLY_URL}
              rootElement={document.getElementById("root")}
              text="Reach out to discuss"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceSectionNew;
