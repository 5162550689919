import React, { useEffect, useRef, useState } from "react";
// import MainLayout from "../../layouts/MainLayout";
// import LandingSection from "../../section/LandingSection";
import Header from "./Header";

import HeroSection from "./HeroSection";
import ContactForm from "./ContactForm";

import whatWeDo from "../../../assets/marketing/what-we-do-new.webp";
import whatWeDoSec from "../../../assets/marketing/what-we-do-sub.webp";

import whatWeDo2 from "../../../assets/marketing/service2.webp";
import whatWeDoSec2 from "../../../assets/marketing/service2Sec.webp";

import whatWeDo3 from "../../../assets/marketing/service3.webp";
import whatWeDoSec3 from "../../../assets/marketing/service3sec.webp";

import broker1 from "../../../assets/marketing/logo_1.webp";
import broker2 from "../../../assets/marketing/logo_2.webp";
import broker3 from "../../../assets/marketing/logo_3.webp";
import broker4 from "../../../assets/marketing/logo_4.webp";
import broker5 from "../../../assets/marketing/logo_5.webp";
import broker6 from "../../../assets/marketing/logo_6.webp";
import broker7 from "../../../assets/marketing/logo_7.webp";
import broker8 from "../../../assets/marketing/logo_8.webp";
import broker9 from "../../../assets/marketing/logo_9.webp";
import JaneImg from "../../../assets/marketing/jane.webp";
import homeImg from "../../../assets/marketing/image_2.webp";
import mobileImg from "../../../assets/marketing/img_1.webp";
import digital from "../../../assets/marketing/digitalImg.webp";
import houseImg from "../../../assets/marketing/image_1.webp";
import lineImg from "../../../assets/marketing/Line.webp";
import arrow from "../../../assets/marketing/Arrow 1.webp";
import dimond from "../../../assets/marketing/dimond.svg";
import gift from "../../../assets/marketing/gift.svg";
import refund from "../../../assets/marketing/refund.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import LandingFooter from "../../landingFooter/LandingFooter";

import "./styles.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const serviceItems = [
  {
    image1: whatWeDo,
    image2: whatWeDoSec,
    title: "Power of stories and connections",
    desc: "At The Jane Rothe Co., we recognize that stories are more than just words—they are powerful tools that connect people, build trust, and keep you top of mind. Our marketing approach is deeply rooted in storytelling, crafting narratives that resonate with potential clients and strengthen connections within your network so that when real estate needs arise, you’re the first person they think of and call.",
  },
  {
    image1: whatWeDo2,
    image2: whatWeDoSec2,
    title: "Empowering Real Estate Agents to Build Lasting Relationships",
    desc: "Our approach combines the best of both worlds: the reliability of traditional marketing with the innovation of modern strategies. At The Jane Rothe Co., we leverage time-tested methods that build trust and familiarity while integrating fresh, forward-thinking techniques tailored to today’s fast-paced real estate market. By staying on top of emerging trends and technologies, we ensure that our clients are not just keeping up but leading the way in their marketing efforts.",
  },
  {
    image1: whatWeDo3,
    image2: whatWeDoSec3,
    title:
      "Blending the Familiarity of Traditional Marketing with Cutting-Edge Strategies",
    desc: "Our approach combines the best of both worlds: the reliability of traditional marketing with the innovation of modern strategies. At The Jane Rothe Co., we leverage time-tested methods that build trust and familiarity while integrating fresh, forward-thinking techniques tailored to today’s fast-paced real estate market. By staying on top of emerging trends and technologies, we ensure that our clients are not just keeping up but leading the way in their marketing efforts.",
  },
];

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceItems.length);
  };
  const images = [
    broker1,
    broker2,
    broker3,
    broker4,
    broker5,
    broker6,
    broker7,
    broker8,
    broker9,
  ];

  const settings = {
    infinite: true, // Infinite loop
    speed: 5000, // Slow & smooth transition
    slidesToShow: 5, // Number of images visible at a time
    slidesToScroll: 1, // Move one image at a time
    autoplay: true, // Enable auto-scroll
    autoplaySpeed: 0, // Instant transition (continuous)
    cssEase: "linear", // Smooth movement
    pauseOnHover: false, // No pause on hover
    arrows: false, // Hide navigation arrows
    dots: false, // Hide navigation dots
    responsive: [
      {
        breakpoint: 1024, // Large screens (tablets, smaller desktops)
        settings: {
          slidesToShow: 4, // Show 4 slides
        },
      },
      {
        breakpoint: 768, // Medium screens (tablets)
        settings: {
          slidesToShow: 3, // Show 3 slides
        },
      },
      {
        breakpoint: 640, // Small screens (phones)
        settings: {
          slidesToShow: 2, // Show 2 slides
        },
      },
      {
        breakpoint: 480, // Extra small screens
        settings: {
          slidesToShow: 1, // Show 1 slide
        },
      },
    ],
  };

  return (
    <>
      <div className="min-h-screen flex flex-col bg-white max-w-[1280px] w-full mx-auto">
        <div className="mx-auto text-justify max-w-7xl flex overflow-hidden flex-col w-full items-center bg-white">
          <Header></Header>
          <HeroSection></HeroSection>
          <main className="absolute top-[calc(100vh)] max-w-[1280px] mx-auto w-full z-10 ">
            <div>
              <div className="relative bg-alabaster pt-[168px] pb-[152px] aspect-[1280/832] w-full maxdesktop:hidden">
                <img
                  className="absolute max-w-[130.1px] w-full z-30 h-[242px] top-[30px] right-[150px]"
                  src={JaneImg}
                ></img>

                <div className="w-full">
                  <div className="aspect-[295/128]  relative  max-w-[1180px] mx-auto  w-full bg-aliceBlue flex flex-wrap">
                    <div className="w-full max-w-[440px] h-full">
                      <div className="absolute aspect-[420/632] max-w-[420px] flex flex-col left-[20px] bottom-[-52px] justify-between  w-full bg-raisinBlack">
                        <div className="font-canela-trial text-[32px] font-light leading-[32px] text-white  my-auto pt-[15px]  flex  justify-center text-center">
                          What we do
                        </div>
                        <div className="max-w-[390px] aspect-[390/547] w-full m-[15px]">
                          <img
                            className="w-full rounded-none p-0 m-0 h-full"
                            alt="what we do service"
                            src={serviceItems[currentIndex].image1}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-wrap aspect-[740/512] max-w-[740px] w-full ">
                      <div className="flex flex-col pb-[22px] pt-[12px] pl-[50px] h-full max-w-[355px] w-full font-montserrat-regular text-left">
                        <div className="text-[20px] mb-[25px] font-medium leading-[26px]">
                          {serviceItems[currentIndex].title}
                        </div>
                        <div className="text-[16px] grow font-medium leading-[22.4px] text-[#333333]">
                          {serviceItems[currentIndex].desc}
                        </div>
                        <div
                          className="max-w-[170px] max-h-[50px] h-full flex 
                items-center justify-center border-[0.8px] border-[solid] border-[#252525]"
                        >
                          <button
                            onClick={handleNext}
                            className="text-[16px] font-normal leading-[16px] text-center h-[50px]"
                          >
                            View next{" "}
                            <ArrowForwardIosIcon className="w-[16px] h-auto opacity-[0px] text-[#252525]" />{" "}
                          </button>
                        </div>
                      </div>
                      <div className="w-full aspect-[385/232]  flex flex-col max-w-[385px]">
                        <div className="grow h-6"></div>
                        <div className="bg-raisinBlack max-w-[343px] w-full aspect-[343/232] mr-[20px] mb-[28px] ml-[22px] p-[8px] pr-[29px]">
                          <div className="aspect-[306/216] w-full max-w-[306px] ">
                            <img
                              alt="what we do secrvice second"
                              className="w-full rounded-none p-0 m-0 h-full object-center"
                              src={serviceItems[currentIndex].image2}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-alabaster py-[100px] w-full mindesktop:hidden px-[30px] ">
                <div className="bg-aliceBlue flex md:flex-row flex-col p-[20px]">
                  <div className="w-full max-w-[420px] bg-raisinBlack relative z-10 mx-auto">
                    <div className="font-canela-trial text-[32px] font-light leading-[32px] text-white my-auto pt-[15px]  flex  justify-center text-center">
                      What we do
                    </div>
                    <div className="p-[10px]">
                      <img
                        alt="homeImg"
                        src={serviceItems[currentIndex].image1}
                        className="w-full max-w-[390px] h-full object-cover m-0 rounded-[2px] hover-transform-3d !mx-auto"
                      />
                    </div>
                  </div>
                  <div className="max-w-[355px] w-full font-montserrat-regular text-left md:ml-[30px] h-full mt-[20px] mx-auto">
                    <div className="text-[20px] mb-[25px] font-medium leading-[26px]">
                      {serviceItems[currentIndex].title}
                    </div>
                    <div className="text-[16px] grow font-medium leading-[22.4px] text-[#333333]">
                      {serviceItems[currentIndex].desc}
                    </div>
                    <div className="max-w-[170px] max-h-[50px] h-full flex items-center justify-center border-[0.8px] border-[solid] border-[#252525] maxdesktop:mt-[30px]">
                      <button
                        onClick={handleNext}
                        className="text-[16px] font-normal leading-[16px] text-center h-[50px]"
                      >
                        View next{" "}
                        <ArrowForwardIosIcon className="w-[16px] h-auto opacity-[0px] text-[#252525]" />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full bg-raisinBlack pb-[51.62px] pt-[33px] px-[20px]">
              <div className="font-canela-trial text-[32px] font-light leading-[32px] text-center text-white pb-[50px]">
                Broker logos
              </div>
              {/* <div className="flex gap-[20px] max-h-[500px] mx-auto w-full h-auto sm:max-h-[300px] lg:max-h-[400px] scrollbar-hide xl:gap-[0px]">
                  <img
                    alt="broker1"
                    src={broker1}
                    className="m-0 p-0 h-auto w-auto rounded-none object-cover transform hover:scale-110 transition duration-300 opacity-50 hover:opacity-100"
                  />
                </div> */}

              <div className="w-full overflow-hidden px-4">
                <Slider {...settings}>
                  {images.concat(images).map((image, index) => (
                    <div key={index} className="px-6">
                      <img
                        src={image}
                        alt={`broker${index + 1}`}
                        className="m-0 w-auto h-[52px] rounded-none transform hover:scale-110 transition duration-300 opacity-50 hover:opacity-100"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

            <div className="w-full py-14 bg-white">
              <div className="w-full pl-[128px] pr-[30px] grid grid-cols-12 sm:gap-x-5 desktop:pl-[30px]">
                <div className="col-span-12 lg:col-span-3">
                  <div className="w-full desktop:mx-[auto] desktop:max-w-[520px]">
                    <h2 className="font-canela-trial text-[32px] font-light leading-[41.6px] text-left">
                      Our
                    </h2>
                    <h2 className="font-canela-trial text-[32px] font-light leading-[41.6px] text-left">
                      services
                    </h2>
                    <p className="font-montserrat-regular w-[133px] text-[16px] font-medium leading-[22.4px] text-[#333333] my-[37px] text-left">
                      All services are monthly subscriptions. The monthly cost
                      does not include the hard costs of marketing.
                    </p>

                    <div
                      className="max-w-[170px] max-h-[50px] h-full backdrop-filter backdrop-blur-[10px] flex 
                          items-center justify-center border-[0.8px] border-raisinBlack"
                    >
                      <button className="bg-[#FFFFFF0D] font-montserrat-regular text-[16px] font-normal leading-[16px] text-center h-[50px] text-raisinBlack">
                        <Link to="/services">View more </Link>
                      </button>
                    </div>

                    <img
                      alt="arrow"
                      src={arrow}
                      className="m-0 p-0 rounded-none w-full object-contain max-w-[235px]"
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-6">
                  <div className="relative w-full desktop:mx-[auto] desktop:max-w-[520px]">
                    <div>
                      <div className="w-full max-w-[510px] bg-raisinBlack z-10 relative">
                        <div className="p-[10px]">
                          <img
                            alt="houseImg"
                            src={houseImg}
                            className="w-full max-w-[486px] filter brightness-[1.3] h-[182px] object-cover m-0 rounded-[2px] hover-transform-3d "
                          />
                          <p className="font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite">
                            The Premier Package
                          </p>
                          <p className="font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite">
                            $850
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-[70px] w-full max-w-[510px] justify-between desktop:mt-[20px] smallView:flex-col">
                      <div className="w-full max-w-[217px] h-[319px] bg-raisinBlack z-10 smallView:mx-auto mobile:mt-[0px] smallView:max-w-[unset] smallView:h-full">
                        <div className="p-[10px]">
                          <img
                            alt="mobileImg"
                            src={mobileImg}
                            className="w-full max-w-[201px] h-[231px] object-cover m-0 rounded-[2px] hover-transform-3d smallView:max-w-[unset] smallView:h-full"
                          />
                          <p className="font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite">
                            Social Media
                          </p>
                          <p className="font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite">
                            $395
                          </p>
                        </div>
                      </div>
                      <div className="w-full max-w-[224px] desktop:max-w-[217px] desktop:h-[319px] bg-raisinBlack z-10 ml-[20px] smallView:mt-[20px] smallView:max-w-[unset] smallView:mx-auto smallView:h-full">
                        <div className="p-[10px]">
                          <img
                            alt="digital"
                            src={digital}
                            className="w-full max-w-[208px] filter brightness-[1.3] h-[287px] desktop:max-w-[201px] desktop:h-[231px] object-cover m-0 rounded-[2px] hover-transform-3d smallView:max-w-[unset] smallView:h-full"
                          />
                          <p className="font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite">
                            Digital Billboards
                          </p>
                          <p className="font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite">
                            $150
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      alt="lineImg"
                      src={lineImg}
                      className="absolute top-[130px] left-[60px] w-full h-[300px] m-0 transform rotate-[-33.35] rounded-none desktop:hidden"
                    />
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-3 desktop:hidden">
                  <div className="w-full max-w-[297px] bg-raisinBlack mt-[160px] relative z-10 desktop:mt-[20px]">
                    <div className="p-[10px]">
                      <img
                        alt="homeImg"
                        src={homeImg}
                        className="w-full max-w-[273px] filter brightness-[1.3] h-[402px] object-cover m-0 rounded-[2px] hover-transform-3d"
                      />
                      <p className="font-montserrat-regular my-[12px] text-[16px] font-medium leading-[16px] text-clientWhite">
                        Brokerage Package
                      </p>
                      <p className="font-montserrat-regular text-[14px] font-normal leading-[14px] text-clientWhite">
                        $1,600
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-clientNero w-full">
              <div className="w-full px-[40px] py-[40px] grid grid-cols-12 sm:gap-x-5 gap-y-7 tabletSlider:px-[128px]">
                <div className="col-span-12 md:col-span-4">
                  <img
                    alt="dimond"
                    src={dimond}
                    className="w-full p-0 rounded-none max-w-[50px] m-0 h-[50px] object-contain mb-[10px] mobile:max-w-[40px] mobile:h-[40px]"
                  />
                  <h3 className="font-montserrat-regular mb-[10px] text-[18px] font-medium leading-[18px] text-left text-clientWhite">
                    Superior quality
                  </h3>
                  <p className="font-montserrat-regular text-[14px] font-normal leading-[18.2px] text-left text-clientGainsboro w-full max-w-[291px]">
                    We take pride in our process to ensure only products of the
                    highest quality are produced.
                  </p>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <img
                    alt="gift"
                    src={gift}
                    className="w-full p-0 rounded-none max-w-[50px] m-0 h-[50px] object-contain mb-[10px] mobile:max-w-[40px] mobile:h-[40px]"
                  />
                  <h3 className="font-montserrat-regular mb-[10px] text-[18px] font-medium leading-[18px] text-left text-clientWhite">
                    Premium packaging
                  </h3>
                  <p className="font-montserrat-regular text-[14px] font-normal leading-[18.2px] text-left text-clientGainsboro w-full max-w-[291px]">
                    All items are wrapped in gorgeous packaging with how-to
                    style instructions and stardust.
                  </p>
                </div>
                <div className="col-span-12 md:col-span-4">
                  <img
                    alt="refund"
                    src={refund}
                    className="w-full p-0 rounded-none max-w-[50px] m-0 h-[50px] object-contain mb-[10px] mobile:max-w-[40px] mobile:h-[40px]"
                  />
                  <h3 className="font-montserrat-regular mb-[10px] text-[18px] font-medium leading-[18px] text-left text-clientWhite">
                    Refund policy
                  </h3>
                  <p className="font-montserrat-regular text-[14px] font-normal leading-[18.2px] text-left text-clientGainsboro w-full max-w-[291px]">
                    Our satisfaction guarantee ensures that we are satisfied
                    only if you are.
                  </p>
                </div>
              </div>
            </div>
            <ContactForm></ContactForm>
            <LandingFooter></LandingFooter>
          </main>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
