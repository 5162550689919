import React from "react";
import Header from "../header/Header";
import LandingFooter from "../landingFooter/LandingFooter";
const MainLayout = ({ children }) => {
  return (
    <>
      <div className="min-h-screen flex flex-col">
        <Header />

        <div className="flex-grow">{children}</div>

        <LandingFooter />
      </div>
    </>
  );
};

export default MainLayout;
