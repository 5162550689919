import mask1 from "../../../assets/marketing/mask1.webp";
import mask2 from "../../../assets/marketing/mask2.webp";
import mask3 from "../../../assets/marketing/mask3.webp";
import mask4 from "../../../assets/marketing/mask4.webp";
import mask5 from "../../../assets/marketing/mask5.webp";

export const ServiceCardData = [
  {
    id: 1,
    headingStart: "Digital Billboards - Retargeting",
    headingMiddle: "$150",
    headingEnd: "MONTH",
    image: mask1,
    listingPint: [
      "Brand Building",
      "Increase Following",
      "Increase Engagement",
      "Increase Traffic",
      "Personalized Images",
      "Posts 3x week",
    ],
  },
  {
    id: 2,
    headingStart: "Social Media Marketing",
    headingMiddle: "$395",
    headingEnd: "MONTH",
    image: mask2,
    listingPint: [
      "Strategically Placed Internet Ads",
      "Banner Advertising",
      "Mobile Advertising",
      "Target Your Sphere of Influence",
      "Target Lookalike Audiences",
      "Target Geo-Specific Audiences",
      "Personalized Designs",
      "Software Setup & Management",
      "Ad Optimization",
    ],
  },
  {
    id: 3,
    headingStart: "Email Marketing",
    headingMiddle: "$125",
    headingEnd: "MONTH",
    image: mask3,
    listingPint: [
      "Listing - We help you market your listings and provide you with material that impresses your clients.",
      "Closing - We manage multiple closing tasks, including reputation management and client nurturing.",
    ],
  },
  {
    id: 4,
    headingStart: "Listing and Post-Close Execution",
    headingMiddle: "$395",
    headingEnd: "MONTH",
    image: mask4,
    listingPint: [
      "Graphic Design",
      "Campaign Management",
      "Hard Mailers 12, 6, or 4x Yearly",
      "Mailers Sent to Database",
    ],
  },
  {
    id: 5,
    headingStart: "Direct Mail Marketing Starting",
    headingMiddle: "$195",
    headingEnd: "MONTH",
    image: mask5,
    listingPint: [
      "24 Emails",
      "Send to Database 1-2x a Month",
      "Option to Send up to 4x a Month",
      "Branded Email Templates",
      "Software Setup & Management",
    ],
  },
];
