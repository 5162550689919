import * as React from "react";
import style from "./HeroSection.module.css";
import LandingPageImg from "../../../assets/marketing/landing-banner.png";
import LogoImg from "../../../assets/marketing/janeRotheLogo.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { CONTACT_US_CALENDLY_URL } from "../../../utils/constant/constants";
import { PopupButton } from "react-calendly";

export default function HeroSection() {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisibleBtn, setIsVisibleBtn] = React.useState(false);

  React.useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);

  React.useEffect(() => {
    // Delay observer initialization by 2 seconds
    const timeout = setTimeout(() => {
      const element = document.getElementById("slide-up-element");
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.5 }
      );

      if (element) {
        observer.observe(element);
      }

      return () => {
        if (element) observer.unobserve(element);
      };
    }, 1000); // 2-second delay

    return () => clearTimeout(timeout); // Clear timeout if component unmounts
  }, []);

  React.useEffect(() => {
    // Delay observer initialization by 2 seconds
    const timeout = setTimeout(() => {
      const element = document.getElementById("slide-up-element-btn");
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisibleBtn(true);
          }
        },
        { threshold: 0.5 }
      );

      if (element) {
        observer.observe(element);
      }

      return () => {
        if (element) observer.unobserve(element);
      };
    }, 2000); // 2-second delay

    return () => clearTimeout(timeout); // Clear timeout if component unmounts
  }, []);

  return (
    <div>
      <div className="fixed min-h-[100dvh] left-0 right-0 flex-col w-full">
        <div className="flex justify-center items-center relative max-w-[1280px] mx-auto w-full text-white uppercase min-h-[100dvh]">
          <img
            loading="lazy"
            src={LandingPageImg}
            alt="Hero background"
            className={`${style.heroBg} `}
          />
        </div>
      </div>

      <div className="relative z-10 xl:top-[260px] top-[200px]">
        <div className="min-h-[100dvh] text-white uppercase">
          <div className="text-center z-10 w-[788px] p-5">
            <img
              loading="lazy"
              src={LogoImg}
              alt="Company branding"
              className="object-contain w-full aspect-[4.59] md:max-w-[660px] h-auto image-unset !mx-auto max-w-[550px] mobile:max-w-[400px] smallView:max-w-[300px]"
            />
          </div>

          <div className="flex bottom-1 flex-col items-center text-center max-w-full w-[788px] p-5">
            <div
              id="slide-up-element"
              className={`${
                isVisible ? "animate-slideUp" : "opacity-0"
              } normal-case mt-5 max-w-[468px] mb-[25px] text-[30px] font-medium leading-[48px] smallView:max-w-[327px] mobile:max-w-[408px]`}
            >
              Growth strategies & marketing for{" "}
              <span>
                <span className="text-primary">
                  REALTORS<span class="text-[20px] align-super"> ®</span>
                </span>
              </span>
            </div>

            <button
              id="slide-up-element-btn"
              className={`${
                isVisibleBtn ? "animate-slideUp" : "opacity-0"
              } font-montserrat-regular text-[16px] font-normal leading-[16px] normal-case border-[0.8px] border-[solid] border-[#FFFFFF] max-w-[220px] w-full px-[5px] py-[17px] backdrop-filter backdrop-blur-[10px] bg-[#0000000D]`}
              tabIndex="0"
            >
              <PopupButton
                tabIndex="0"
                url={CONTACT_US_CALENDLY_URL}
                rootElement={document.getElementById("root")}
                text="Schedule a meeting"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
