import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signupSubcribeSchema } from "../../../utils/validation/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { addErrors, clearErrors } from "../../../store/user";
import WorkingHandVid from "../../../assets/marketing/Homepage-SignUpforEmail.mp4";
import ModalAlert from "./modalPopup";
import React, { useState } from "react";
import SublyApi from "../../../helpers/Api";
import { EMAIL_EXIST } from "../../../utils/constant/constants";

export default function ContactForm() {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Functions to open and close the modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const apiError = useSelector((st) => st.user.errors);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(signupSubcribeSchema),
  });

  // sets error to null when the component first mounts and when it dismounts.
  React.useEffect(() => {
    dispatch(clearErrors());
    return () => {
      dispatch(clearErrors()); // dismount
    };
  }, []);

  // const handleConatctSubmit = (data) => {
  //   dispatch(clearErrors());

  //   console.log("Form Submitted Data:", data);

  //   const options = {
  //     method: "POST",

  //     headers: {
  //       accept: "application/json",

  //       "content-type": "application/json",

  //       "X-API-Key": "58a8249e-f8bc-43f2-945f-ea950f5590a5",
  //     },

  //     body: JSON.stringify({
  //       unsubscribe_all: false,

  //       list_ids: ["b37fc152-a434-472c-bf10-f41c086e25a2"],

  //       email: data?.email,

  //       field_values: [
  //         { name: "First_Name", string: data?.firstName },
  //         { name: "Last_Name", string: data?.lastName },
  //       ],
  //     }),
  //   };

  //   fetch(
  //     "https://api.bigmailer.io/v1/brands/862ef14e-667a-4db5-a106-441e1d24fe1a/contacts?validate=false",
  //     options
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res?.type) {
  //         openModal();
  //         console.error(res.message);
  //         dispatch(addErrors(res.message));
  //       } else {
  //         openModal();
  //         reset();
  //       }
  //     })

  //     .catch((err) => console.error(err));
  // };

  const downloadFromAPI = async () => {
    const response = await fetch("https://jayro.herokuapp.com");
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "JaneRothes.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleConatctSubmit = async (data) => {
    try {
      const response = await SublyApi.addNewContactToMailer({ data });
      console.log("response", response);
      if (response?.bigmailer?.code == EMAIL_EXIST || response?.bigmailer?.id) {
        downloadFromAPI();
        reset();
      }
    } catch (error) {
      console.error("Error adding contact:", error);
    }
  };

  return (
    <div className="max-w-[1280px] w-full mx-auto">
      <div className="flex relative flex-col items-end self-stretch px-[50px] py-10  w-full text-lg text-white min-h-[586px]">
        {/* <img
          loading="lazy"
          src={SignUpImg}
          className="m-0 rounded-none object-cover absolute inset-0 size-full"
        /> */}
        <video
          className="m-0 rounded-none object-cover absolute inset-0 size-full"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={WorkingHandVid} type="video/mp4" />
          {/* <source
            src="https://cdn.prod.website-files.com/617c3138ae96290382b971a0/6187ff1a80976a1fd1f8dcd5_how_to_tie_your_scarf_trimmed-transcode.webm"
            type="video/webm"
          /> */}
        </video>
        <div className="bg-[#00000090] opacity-80 p-4">
          <form
            onSubmit={handleSubmit(handleConatctSubmit)}
            className="relative max-w-[300px] w-full "
          >
            <div className="text-red-600 mt-3 ">
              {/* {apiError.map((e, indx) => (
              <div key={indx}>
                <small>ERROR: {e}</small>
              </div>
            ))} */}
            </div>
            <h2 className="font-montserrat-regular text-white text-[25px] font-medium leading-[37.5px] text-left">
              Sign up for Jane rothe Updates
            </h2>

            <div className="mt-[25px]">
              <input
                {...register("firstName")}
                type="text"
                id="firstName"
                placeholder="Enter your first name"
                className="font-montserrat-regular bg-transparent text-white placeholder:#9E9E9E px-[15px] py-[18px] text-[14px] font-normal leading-[14px] text-left wax-w-[250px] w-full border-[0.8px] border-[solid] border-[#FFFFFF] [box-shadow:0px_0px_2px_0px_#00000026] h-[50px] gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none"
              />
              {errors["firstName"] && (
                <p className="mt-1 text-sm text-red-600">
                  {errors["firstName"].message}
                </p>
              )}
            </div>

            <div className="mt-[15px]">
              <input
                type="text"
                id="lastName"
                {...register("lastName")}
                placeholder="Enter your last name"
                className="font-montserrat-regular bg-transparent text-white placeholder:#9E9E9E px-[15px] py-[18px] text-[14px] font-normal leading-[14px] text-left wax-w-[250px] w-full border-[0.8px] border-[solid] border-[#FFFFFF] [box-shadow:0px_0px_2px_0px_#00000026] h-[50px] gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none"
              />
              {errors["lastName"] && (
                <p className="mt-1 text-sm text-red-600">
                  {errors["lastName"].message}
                </p>
              )}
            </div>

            <div className="mt-[15px]">
              <input
                type="text"
                id="email"
                {...register("email")}
                placeholder="Enter your email address"
                className="font-montserrat-regular bg-transparent text-white placeholder:#9E9E9E px-[15px] py-[18px] text-[14px] font-normal leading-[14px] text-left wax-w-[250px] w-full border-[0.8px] border-[solid] border-[#FFFFFF] [box-shadow:0px_0px_2px_0px_#00000026] h-[50px] gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none"
              />
              {errors["email"] && (
                <p className="mt-1 text-sm text-red-600">
                  {errors["email"].message}
                </p>
              )}
            </div>
            <div className="mt-[10px] text-base leading-5">
              <div class="flex items-center space-x-3">
                <div className="self-start">
                  <input
                    type="checkbox"
                    id="customCheckbox"
                    class="w-6 h-6 border-2 border-white rounded-md appearance-none cursor-pointer relative checked:border-white checked:bg-transparent checked:before:content-['✓'] checked:before:text-white checked:before:text-xl checked:before:font-bold checked:before:flex checked:before:absolute checked:before:top-1/2 checked:before:left-1/2 checked:before:transform checked:before:-translate-x-1/2 checked:before:-translate-y-1/2"
                  />
                </div>
                <div className="font-montserrat-regular flex-auto text-[14px] font-normal leading-[17.5px] text-left">
                  By entering your email address you are accepting our{" "}
                  <a href="#" className="text-white">
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a href="#" className="text-white">
                    Privacy
                  </a>{" "}
                  &{" "}
                  <a href="#" className="text-white">
                    Cookie Policy.
                  </a>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="px-[38px] mt-[39px] py-[14px] border-[0.8px] !border-[#FFFFFF] bg-transparent font-montserrat-regular max-w-[150px] w-full h-[45px]  gap-0 rounded-tl-[3px] rounded-br-none rounded-tr-none rounded-bl-none text-[18px] font-normal leading-[18px] text-left"
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>

      {isModalOpen && (
        <ModalAlert isModalOpen={isModalOpen} closeModal={closeModal} />
      )}
    </div>
  );
}
