import React from "react";
import Logo2 from "../../assets/marketing/Logo2.svg";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";
import { CONTACT_US_CALENDLY_URL } from "../../utils/constant/constants";
import SocialLinks from "./SocialLinks";

const LandingFooter = () => {
  return (
    <div>
      <div
        className="max-w-[1280px] mx-auto h-[271px] bg-clientBlack flex justify-between items-center flex-col pt-[20px] pb-[20px]
       mobile:pl-[5px] mobile:pr-[5px]"
      >
        <img
          src={Logo2}
          alt="logo"
          className="w-[258px] h-[56.2px] m-[0] rounded-none"
        ></img>

        <div
          className="border-2 border-solid [border-image-source:linear-gradient(180deg,_rgba(245,_204,_92,_0.2)_0%,_rgba(199,_171,_95,_0.2)_23%,_rgba(245,_204,_92,_0.2)_62%,_rgba(199,_171,_95,_0.2)_100%)] [border-image-slice:1] max-w-[461px] w-full h-[50px] flex justify-evenly items-center mx-auto
         mobile:w-full"
        >
          <div>
            <p className="font-canela-trial text-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">
              <Link to="services"> Services </Link>
            </p>
          </div>

          <div>
            <p className="font-canela-trial text-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">
              <Link to="about-us"> About us </Link>
            </p>
          </div>

          <div>
            <p className="font-canela-trial text-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">
              <PopupButton
                url={CONTACT_US_CALENDLY_URL}
                rootElement={document.getElementById("root")}
                text="Contact us"
                // textColor="#ffffff"
                // color="#00a2ff"
              />
              {/* <Link to="contact-us" >   CONTACT US </Link> */}
            </p>
          </div>

          <div>
            <p className="font-canela-trial text-[18px] mobile:text-[12px] font-normal leading-[18px] text-left m-0 text-clientWhite">
              <Link to="blog"> Blog </Link>
            </p>
          </div>
        </div>

        <div className="w-full mx-auto h-[50px] flex justify-center gap-5 ">
          {SocialLinks.map((item, index) => (
            <a
              href={item.link}
              key={index}
              rel="noopener noreferrer"
              target="_blank"
            >
              <div>
                <img
                  src={item.image}
                  alt={item.alt}
                  className="w-[50px] h-[50px] m-[0]"
                ></img>
              </div>
            </a>
          ))}
        </div>
      </div>

      <div
        className="max-w-[1280px] mx-auto h-[50px] bg-clientWhite flex justify-between  items-center pl-[100px] pr-[100px]
        tablet:pl-[20px] tablet:pr-[20px]
        mobile:pl-[5px] mobile:pr-[5px] smallView:flex-col smallView:h-[unset]"
      >
        <div>
          <p
            className="font-montserrat-regular text-[20px] tablet:text-[14px] font-normal leading-[20px] text-left m-0
            mobile:text-[10px]"
          >
            All rights reserved.
          </p>
        </div>

        <div>
          <p
            className="font-montserrat-regular text-[20px] tablet:text-[14px] font-normal leading-[20px] text-left m-0
           mobile:text-[10px]"
          >
            © {new Date().getFullYear()} Jane Rothe Co.
          </p>
        </div>

        <div>
          <p
            className="font-montserrat-regular text-[20px] tablet:text-[14px] font-normal leading-[20px] text-left m-0
           mobile:text-[10px]"
          >
            <span className="">
              Made with
              <span className="mx-1 text-red-500">❤️</span> from California
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
